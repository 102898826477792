import { Box, CircularProgress } from '@mui/material';

export default function PleaseWait() {
  return (
    <Box px={5} py={20} display="flex" flexDirection="column" gap={2} width="100%" alignItems="center">
      <span>Aguarde, carregando…</span>
      <CircularProgress />
    </Box>
  );
}
