import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ErrorInfo } from 'react';

const isServer = typeof window === 'undefined';

if (process.env.NEXT_PHASE !== 'phase-production-build') {
  const commonConfig = {
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '',
    appVersion: process.env.NEXT_BUILD_ID,
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ['production'],
    logger: null,
  };

  if (isServer) {
    Bugsnag.start({ ...commonConfig, plugins: [new BugsnagPluginReact(), require('@bugsnag/plugin-aws-lambda')] });
  } else {
    Bugsnag.start({ ...commonConfig, plugins: [new BugsnagPluginReact()] });
  }
}

export function handleError(e: Error, errorInfo?: ErrorInfo) {
  if (!e) return;

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    return console.error(e);
  }

  Bugsnag.notify(e, (ev) => {
    ev.unhandled = true;
    if (errorInfo) ev.addMetadata('react', errorInfo);
  });
}
