import axios from 'axios';
import _ from 'lodash';
import { WalkBuilder } from 'walkjs';

import {
  ICompanyFormData,
  ICompanyProfileFormData,
  ICouponEditForm,
  IErrorResponse,
  IFeedPost,
  IFileUploadInfo,
  IMemberForEditing,
  IMyInfo,
  INewFeedPost,
} from '../types/main-api';
import { IOnboardingStepOnePayload, IOnboardingStepThreePayload, IOnboardingStepTwoPayload } from '../types/onboarding';
import { ReplaceTypeOnProps } from '../types/tools';

import { BaseClientSideApiClient } from './client-side';

export class ApiClient extends BaseClientSideApiClient {
  /**
   * Tries to log in into the platform. Returns true if successful, false otherwise.
   */
  async login(email: string, password: string): Promise<IMyInfo | null> {
    try {
      return await this.client.post<IMyInfo>('/api/auth/login', { email, password }).then((r) => r.data);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response && [400, 401].includes(e.response.status)) return null;

      throw e;
    }
  }

  logout() {
    return this.client.delete('/api/auth/login');
  }

  async register(email: string | undefined) {
    const { status, data } = await this.client.post<IErrorResponse>('/api/register', null, {
      params: { email },
      validateStatus: (s) => [200, 201, 422, 409].includes(s),
    });

    return { status, ...data };
  }

  validateRegistration(code: string, newPassword: string) {
    return this.client
      .post<IMyInfo>('/api/register/validate', null, {
        params: { code, newPassword },
      })
      .then((r) => r.data);
  }

  sendMessage(slug: string, message: string, files: File[]) {
    return Promise.all(files.map((f) => this.upload(f)))
      .then((files) => this.client.post<unknown>(`/api/messages/${slug}`, { message, files }))
      .then((r) => r.data);
  }

  revealCoupon(id: number) {
    return this.client.get<string>(`/api/companies/coupon/${id}`).then((r) => r.data);
  }

  /**
   * Faz o upload concorrente de todos os Blobs do objeto, e retorna um novo objeto com os
   * Blobs substituídos pelo novo caminho do arquivo.
   */
  async uploadImages<T extends object, R extends ReplaceTypeOnProps<T, Blob, string>>(originalObj: T): Promise<R> {
    const changes = new Map<object, { promise: Promise<string>; resolved?: string }>();

    // percorre o objeto procurando por Blobs, e cria uma promessa para cada
    new WalkBuilder()
      .withCallback({
        nodeTypeFilters: ['object'],
        callback: (node) => {
          if (node.val instanceof Blob)
            changes.set(node.val, { promise: this.upload(node.val, `${String(node.key)}.png`) });
        },
      })
      .walk(originalObj);

    // se não tinha nenhum Blob, retorna o original
    if (changes.size === 0) return originalObj as unknown as R;

    // resolve todas as promessas
    for (const c of changes.values()) c.resolved = await c.promise;

    // devolve um clone do objeto, com as promessas resolvidas
    return _.cloneDeepWith(originalObj, (val) => {
      if (!(val instanceof Blob)) return undefined;
      return changes.get(val)?.resolved;
    }) as R;
  }

  async upload(blobOrString: File): Promise<string>;
  async upload(blobOrString: string | Blob, name: string): Promise<string>;
  async upload(blobOrString: string | Blob | undefined, name: string): Promise<string | undefined>;
  async upload(blobOrString: string | Blob | undefined, name?: string) {
    if (!blobOrString || typeof blobOrString === 'string') return blobOrString;

    let type = 'image';
    if (blobOrString instanceof File) {
      type = blobOrString.type.startsWith('image/') ? 'image' : 'file';
      name = blobOrString.name ?? name;
    }

    const {
      data: { signedUrl, headers, path },
    } = await this.client.post<IFileUploadInfo>(`/api/uploads/${type}`, null, {
      params: {
        name,
        contentType: blobOrString.type,
      },
    });

    await this.client.put(signedUrl, blobOrString, { headers, withCredentials: false });

    return path;
  }

  submitOnboardingStepOne(payload: IOnboardingStepOnePayload) {
    return this.client.post<void>('/api/me/onboarding/step-one', payload);
  }

  submitOnboardingStepTwo(payload: IOnboardingStepTwoPayload) {
    return this.client.post<void>('/api/me/onboarding/step-two', payload);
  }

  async submitOnboardingStepThree(payload: IOnboardingStepThreePayload) {
    return this.client.post<void>('/api/me/onboarding/step-three', await this.uploadImages(payload));
  }

  async updatePersonalProfile(payload: IMemberForEditing) {
    const { data } = await this.client.patch<{ id: number; slug: string }>(
      '/api/me/profile/edit',
      await this.uploadImages(payload),
    );
    return data;
  }

  async createCompanyProfile(formData: ICompanyFormData) {
    const { data } = await this.client.post<{ id: number; slug: string }>(
      `/api/me/company`,
      await this.uploadImages(formData),
    );
    return data;
  }

  async updateCompanyProfile(slug: string, formData: ICompanyFormData & ICompanyProfileFormData) {
    const { data } = await this.client.patch<{ id: number; slug: string }>(
      `/api/me/company/${slug}`,
      await this.uploadImages(formData),
    );
    return data;
  }

  deleteCompany(slug: string) {
    return this.client.delete<void>(`/api/me/company/${slug}`);
  }

  updateCoupons(slug: string, formData: ICouponEditForm) {
    return this.client.patch<void>(`/api/me/company/${slug}/coupons`, formData).then((r) => r.data);
  }

  newPost(data: INewFeedPost) {
    return this.client.post<{ id: number }>(`/api/feed`, data).then((r) => r.data);
  }

  newComment(id: number, content: string) {
    return this.client.post<unknown>(`/api/feed/${id}/comment`, JSON.stringify(content)).then((r) => r.data);
  }

  deletePost(id: number) {
    return this.client.delete<unknown>(`/api/feed/${id}`);
  }

  deleteComment(id: number) {
    return this.client.delete<unknown>(`/api/feed/comment/${id}`);
  }

  loadPosts(direction: 'after' | 'before', id?: number) {
    return this.client.get<IFeedPost[]>(`/api/feed/${direction}`, { params: { id } }).then((r) => r.data);
  }

  loadPost(id: number) {
    return this.client
      .get<IFeedPost | null>(`/api/feed/${id}`, { validateStatus: (s) => [200, 404].includes(s) })
      .then((r) => (r.status === 404 ? null : r.data));
  }
}
