import { IPasswordPolicy } from '../passwordPolicy';

import { IOnboardingStepOnePayload, IOnboardingStepThreePayload } from './onboarding';
import { MakeOptional } from './tools';

export interface Paginated<T> {
  total: number;
  pageSize: number;
  hasMore: boolean;
  items: T[];
}

export interface IMemberForList {
  id: number;
  slug: string;
  fullName: string;
  memberSince?: string;
  addressCity?: string;
  addressState?: string;
  instagramHandle?: string;
  contactMail?: string;
  avatar?: string;
  companies: { id: number; slug: string; name: string }[];
}

export interface IMemberShortInfo {
  id: number;
  slug: string;
  fullName: string;
  avatar?: string;
  publicProfile?: boolean;
}

export interface IMember {
  id: number;
  fullName: string;
  slug: string;
  nickname?: string;
  memberSince?: string;
  instagramHandle?: string;
  addressCity?: string;
  addressState?: string;
  about?: string;
  interests: number;
  contactMail?: string;
  avatar?: string;
  publicProfile?: boolean;
  companies: {
    id: number;
    name: string;
    slug: string;
    instagramHandle?: string;
    contactEmail?: string;
    address?: string;
    addressCity?: string;
    addressState?: string;
    services?: string;
    couponCount: number;
    publicBusinessProfile?: boolean;
  }[];
}

export type IMemberForEditing = IOnboardingStepOnePayload &
  IOnboardingStepThreePayload['member'] & { stateId?: number };

export interface ICoupon {
  id: number;
  name: string;
  code: string;
  expirationDate?: string;
}

export interface ICouponEditForm {
  displayCoupons?: boolean;
  couponInstructions?: string;
  coupons: MakeOptional<ICoupon, 'id'>[];
}

export interface ICompany {
  id: string;
  slug: string;
  name: string;
  logo?: string;
  website?: string;
  instagramHandle?: string;
  contactMail?: string;
  address?: string;
  addressCity?: string;
  addressState?: string;
  about?: string;
  services?: string;
  displayCoupons?: boolean;
  couponInstructions?: string;
  coupons: ICoupon[];
  member: IMemberForList;
}

export interface ICompanyFormData {
  incorporationDate: string;
  name: string;
  website: string;
  instagramHandle: string;
  activityField: number;
  services: string;
  targetAudience: number;
  onlineSales: number;
  companyType: number;
  employees: number;
  monthlyRevenue: number;
}

export interface ICompanyProfileFormData {
  publicBusinessProfile?: boolean;
  cityId?: number;
  about?: string;
  logo?: string;
}

export interface IBusinessForList {
  id: number;
  slug: string;
  name: string;
  logo?: string;
  address?: string;
  addressZipcode?: string;
  addressCity?: string;
  addressState?: string;
  services?: string;
  website?: string;
  instagramHandle?: string;
  member: {
    id: number;
    slug: string;
    fullName?: string;
  };
  couponCount: number;
}

export interface IPost {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  coverImage?: string;
  topImage?: string;
  summary: string;
  content?: string;
}

export interface IExternalContent {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  coverImage?: string;
  summary: string;
  category: number;
  uri?: string;
}

export interface IAdvertisement {
  id: number;
  createdAt: string;
  updatedAt: string;
  largeImage?: string;
  mediumImage?: string;
  smallImage?: string;
  altText?: string;
  uri: string;
}

export interface IMessage {
  id: number;
  received: boolean;
  sentAt: string;
  content: string;
  files: string[];
  read: boolean;
}

export interface IMessageSummary {
  correspondentId?: number;
  unreadCount: number;
  slug: string;
  fullName?: string;
  avatar?: string;
  email?: string;
}

export type Role = 'Member' | 'Admin' | 'Partner';

export enum CompanyNeedsUpdate {
  No = 0,
  NearExpiration = 1,
  Blocked = 2,
}

export interface IMyInfo {
  isAuthenticated: boolean;
  id?: number;
  slug?: string;
  name?: string;
  nickname?: string;
  email?: string;
  avatar?: string;
  completedStep: number;
  roles: Role[];
  companies?: { id: number; slug?: string; name?: string; needsUpdate: CompanyNeedsUpdate }[];
}

export interface IState {
  id: number;
  name: string;
  abbreviation: string;
}

export interface ICity {
  id: number;
  name: string;
}

export interface IFileUploadInfo {
  path: string;
  signedUrl: string;
  headers: { [k: string]: string };
}

export interface IEnumDTO<T = number> {
  multiple: boolean;
  options: IEnumOption<T>[];
}

export interface IEnumOption<T = number> {
  key: T;
  name: string;
}

export interface IAllEnums {
  activityField: IEnumDTO;
  companyType: IEnumDTO;
  educationLevel: IEnumDTO;
  employees: IEnumDTO;
  ethnicity: IEnumDTO;
  externalContentCategory: IEnumDTO;
  highlightLevel: IEnumDTO;
  howDidYouFindUs: IEnumDTO;
  interests: IEnumDTO;
  joiningDelay: IEnumDTO;
  joiningReason: IEnumDTO;
  monthlyIncome: IEnumDTO;
  monthlyRevenue: IEnumDTO;
  onlineSales: IEnumDTO;
  pets: IEnumDTO;
  targetAudience: IEnumDTO;
  workModel: IEnumDTO;
}

export interface IAuthor {
  id: number;
  fullName: string;
  avatar?: string;
  email?: string;
  slug?: string;
}

export interface IFeedComment {
  id: number;
  content: string;
  createdAt: string;
  author: IAuthor;
}

export interface INewFeedPost {
  content: string;
  images: string[];
}

export interface IFeedPost {
  id: number;
  content: string;
  images: string[];
  createdAt: string;
  author: IAuthor;
  comments: IFeedComment[];
}

export interface ICodeCheckResult {
  isValid: boolean;
  email: string;
  passwordPolicy: IPasswordPolicy;
}

export interface IErrorResponse {
  message: string;
  details: string;
}

export const YES_NO_ENUM: IEnumDTO<boolean> = {
  multiple: false,
  options: [
    { key: true, name: 'Sim' },
    { key: false, name: 'Não' },
  ],
};

export const CHILDREN_ENUM: IEnumDTO = {
  multiple: false,
  options: [
    { key: 0, name: 'Não' },
    { key: 1, name: '1' },
    { key: 2, name: '2' },
    { key: 3, name: '3' },
    { key: 4, name: '4' },
    { key: 5, name: '5' },
  ],
};

export const COMPLETED_STEPS: IEnumDTO = {
  multiple: false,
  options: [
    { key: 1 << 0, name: 'Nenhum' },
    { key: 1 << 1, name: '1' },
    { key: 1 << 2, name: '2' },
    { key: 1 << 3, name: '3' },
  ],
};
