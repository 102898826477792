import { SxProps } from '@mui/material';
import { Component, ErrorInfo, PropsWithChildren } from 'react';

import { handleError } from '../../utils/errors';

import ErrorAlert from './error-alert';

interface Props {
  key?: unknown;
  sx?: SxProps;
}

export default class ErrorBoundary extends Component<PropsWithChildren<Props>> {
  state: { error?: Error } = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    handleError(error, errorInfo);
  }

  render() {
    const { children, sx } = this.props;
    const { error } = this.state;

    if (!error) return <>{children}</>;

    return <ErrorAlert sx={sx} error={error} />;
  }
}
