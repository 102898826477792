/**
 * @file Based on the official Material UI sample.
 *
 * {@link https://github.com/mui/material-ui/blob/master/examples/nextjs-with-typescript/pages/_app.tsx}
 */

import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ConfirmOptions, ConfirmProvider } from 'material-ui-confirm';
import { AppType } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { SnackbarProvider } from 'notistack';
import { Suspense, useCallback, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { SWRConfig } from 'swr';

import ErrorBoundary from '../components/generic/error-boundary';
import PleaseWait from '../components/generic/please-wait';
import api from '../utils/api';
import { createEmotionCache, theme } from '../utils/theme';
import { AppProps } from '../utils/types/next';

import '../styles/globals.scss';

const clientSideEmotionCache = createEmotionCache();

const confirmOptions: ConfirmOptions = {
  title: 'Tem certeza?',
  confirmationText: 'OK',
  cancellationText: 'Cancelar',
  confirmationButtonProps: { autoFocus: true },
  cancellationButtonProps: { color: 'secondary' },
};

interface Props {}

const MyApp: AppType<Props> = ({ Component, emotionCache = clientSideEmotionCache, pageProps }: AppProps) => {
  const { locale } = useRouter();
  const [shortLocale] = locale ? locale.split('-') : ['pt'];

  const messages = useMemo(() => ({}), []);
  const onI18nError = useCallback(() => null, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>W2W</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"></meta>
        <meta http-equiv='cache-control' content='no-cache'></meta>
        <meta http-equiv='expires' content='0'></meta>
        <meta http-equiv='pragma' content='no-cache'></meta>
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider locale={shortLocale} messages={messages} onError={onI18nError}>
          <ConfirmProvider defaultOptions={confirmOptions}>
            <SnackbarProvider>
              <NextNProgress />
              <SWRConfig value={{ fetcher: api.fetcher }}>
                <Suspense fallback={<PleaseWait />}>
                  <ErrorBoundary>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </Suspense>
              </SWRConfig>
            </SnackbarProvider>
          </ConfirmProvider>
        </IntlProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default MyApp;
