import { Alert, AlertTitle, SxProps } from '@mui/material';
import { isAxiosError } from 'axios';
import { ReactNode } from 'react';

interface Props {
  title?: ReactNode;
  error: Error | string | unknown;
  sx?: SxProps;
}

export default function ErrorAlert({ title, error, sx }: Props) {
  const { message, detail } = useErrorTranslation(error);

  return (
    <Alert severity="error" sx={sx}>
      <AlertTitle>{title || 'Erro'}</AlertTitle>
      {message}
      {!!detail && <small>{JSON.stringify(detail)}</small>}
    </Alert>
  );
}

interface ErrorTranslation {
  code?: number;
  message: string;
  detail?: unknown;
}

function useErrorTranslation(e: Error | string | unknown): ErrorTranslation {
  if (typeof e === 'string') return { message: e };

  if (isAxiosError(e)) {
    const code = e.response?.status;
    const detail = hasDetail(e.response?.data) ? e.response?.data.detail : undefined;

    switch (code) {
      case 401:
        return { code, detail, message: 'É necessário login para acessar este recurso.' };
      case 403:
        return { code, detail, message: 'Você não tem permissão para acessar este recurso.' };
      case 404:
        return { code, detail, message: 'Informações não encontradas.' };
      case 500:
        return { code, detail, message: 'Erro ao comunicar com a API.' };
      default:
        return { code, detail, message: 'Erro interno do servidor' };
    }
  }

  if (isError(e)) return e;

  return { message: JSON.stringify(e) };
}

function hasDetail(data: unknown): data is { detail: unknown } {
  return Boolean(data && typeof data === 'object' && 'detail' in data);
}

function isError(e: unknown): e is Error {
  return e instanceof Error || !!(e && typeof e === 'object' && 'message' in e);
}
