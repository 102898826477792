import Bugsnag from '@bugsnag/js';
import NProgress from 'nprogress';

import { BaseApiClient } from './base';

export abstract class BaseClientSideApiClient extends BaseApiClient {
  constructor() {
    super();

    this.client.interceptors.request.use((req) => {
      NProgress.start();
      Bugsnag.leaveBreadcrumb(
        'API Request',
        { url: req.url, method: req.method, data: JSON.stringify(req.data), headers: req.headers },
        'request',
      );
      return req;
    });
    this.client.interceptors.response.use(
      (v) => {
        NProgress.done();
        return v;
      },
      (err) => {
        NProgress.done();
        return Promise.reject(err);
      },
    );
  }
}
