// noinspection ES6UnusedImports,JSUnusedGlobalSymbols

import createCache from '@emotion/cache';
import {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

const breakPoints = createBreakpoints({});

export const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Roboto, sans-serif',
  },
  palette: {
    primary: {
      main: '#DB1883',
      light: '#ff5cb2',
      dark: '#a40056',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1dd35f',
      light: '#68ff8f',
      dark: '#00a030',
      contrastText: '#000',
    },
    inverted: {
      main: '#ffffff',
      contrastText: '#db1883',
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          th: { fontWeight: 'bold' },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'inverted' },
          style: ({ theme }) => ({
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          }),
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '12px',
          borderRadius: '10px',
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          [breakPoints.only('xs')]: {
            fontSize: '14px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
      styleOverrides: {
        select: {
          backgroundColor: 'white',
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    inverted: Palette['primary'];
  }

  interface PaletteOptions {
    inverted?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    inverted: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    inverted: true;
  }
}

export function createEmotionCache() {
  const cache = createCache({ key: 'css', prepend: true });
  cache.compat = true;
  return cache;
}
