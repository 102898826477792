import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export abstract class BaseApiClient {
  protected readonly client: AxiosInstance;
  public readonly fetcher: ([url, cfg]: string | [string, AxiosRequestConfig]) => Promise<unknown>;
  public readonly baseURL: string;

  protected constructor(
    baseUrl = '/',
    { headers, timeout }: { headers?: Record<string, string | undefined>; timeout?: number } = {},
  ) {
    this.baseURL = baseUrl;
    this.client = axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      headers: { post: { 'Content-Type': 'application/json' }, common: { ...headers } },
      timeout,
    });
    this.fetcher = (args: string | [string, AxiosRequestConfig]) => {
      const [url, cfg] = Array.isArray(args) ? args : [args];
      return this.client.request<unknown>({ ...cfg, url }).then((res) => res.data);
    };
  }
}
